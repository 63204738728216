import { createRef, memo, useCallback, useContext, useEffect } from "react";
import { VariableSizeList } from "react-window";
import CalendarListItem from "app/pages/.shared/CalendarList/CalendarListItem";
import memoizeOne from "memoize-one";
import PropTypes from "prop-types";
import WeekDays from "app/pages/PrePackage/components/DatePickerCalendar/WeekDays";
import getWeeksInMonth from "date-fns/getWeeksInMonth";
import "./CalendarList.scss";
import { findDOMNode } from "react-dom";
import indexOf from "lodash/indexOf";
import AppGlobalsContext from "app/AppGlobalsContext";
import { RESOLUTION } from "app/pages/.shared/responsive/responsiveReducer";

const createItemData = memoizeOne(
	(
		months,
		selectedDate,
		handleDateClick,
		departureDateMin,
		departureDateMax,
		checkDateAvailability
	) => ({
		months,
		selectedDate,
		handleDateClick,
		departureDateMin,
		departureDateMax,
		checkDateAvailability,
	})
);

const itemKey = (index, data) => {
	const item = data.months[index];
	return item;
};

const CalendarList = ({
	onDateSelect,
	months = [],
	selectedDate = [],
	departureDateMin,
	departureDateMax,
	heightOffset,
	checkDateAvailability = () => {},
}) => {
	const pricesList = createRef();

	const handleDateClick = clickedDate => {
		onDateSelect(clickedDate);
	};

	const { resolution } = useContext(AppGlobalsContext);
	const isMobile = resolution === RESOLUTION.SMALL;

	useEffect(() => {
		if (pricesList && pricesList.current) {
			// eslint-disable-next-line react/no-find-dom-node
			const el = findDOMNode(pricesList.current);
			let topPosition = 0;
			const dateToCenter = selectedDate[0] || new Date();

			const monthAndYear = `${dateToCenter.getMonth()}/${dateToCenter.getFullYear()}`;
			const monthIndex = indexOf(months, monthAndYear);

			if (el) {
				months.forEach((month, index) => {
					if (index < monthIndex) {
						topPosition = topPosition + getItemSize(index);
					}
				});

				el.scrollTo({
					behavior: "auto",
					top: topPosition,
				});
			}
		}
	}, []);

	const itemData = createItemData(
		months,
		selectedDate,
		handleDateClick,
		departureDateMin,
		departureDateMax,
		checkDateAvailability
	);

	// 270px is the height of all components in the side panel except the datepicker calendar
	const calendarContainerHeight = window.innerHeight - heightOffset;

	const getItemSize = useCallback(
		index => {
			const month = months[index];

			if (month) {
				const monthValue = Number(month.split("/")[0]);
				const yearValue = Number(month.split("/")[1]);
				const date = new Date(yearValue, monthValue, 15, 15, 0, 0);
				const weeksInMonth = getWeeksInMonth(date, { weekStartsOn: 1 });

				if (weeksInMonth === 6) {
					return isMobile ? 430 : (window.innerHeight + 40) / 1.6;
				}

				return isMobile ? 400 : window.innerHeight / 1.6;
			}
			return 0;
		},
		[months]
	);

	return (
		<div className="date-picker-calendar">
			<WeekDays />
			<VariableSizeList
				ref={pricesList}
				overscanCount={1}
				itemCount={months.length}
				height={calendarContainerHeight}
				estimatedItemSize={375}
				itemSize={getItemSize}
				itemData={itemData}
				itemKey={itemKey}
			>
				{CalendarListItem}
			</VariableSizeList>
		</div>
	);
};

CalendarList.propTypes = {
	onDateSelect: PropTypes.func,
	months: PropTypes.array,
	selectedDate: PropTypes.array,
	departureDateMin: PropTypes.number,
	departureDateMax: PropTypes.number,
	heightOffset: PropTypes.number,
	checkDateAvailability: PropTypes.func,
};

export default memo(CalendarList);
