import SidePanel from "../SidePanel/SidePanel";
import "./Drawer.scss";
import classNames from "classnames";
import PropTypes from "prop-types";
import useDrawerOverlay from "app/utils/hooks/useDrawerOverlay";
import useClickAway from "app/utils/hooks/useClickAway";
import { useRef } from "react";

const Drawer = ({
	children,
	drawerClassName,
	sidePanelClassName,
	style,
	clickAwayIgnoreComponents,
	...props
}) => {
	const { isOpen, parentRef, onBackdropClick = () => {} } = props;
	useDrawerOverlay(parentRef, isOpen);

	const sidePanelRef = useRef(null);

	useClickAway(
		sidePanelRef,
		() => {
			onBackdropClick();
		},
		clickAwayIgnoreComponents
	);

	return (
		<SidePanel {...props} className={sidePanelClassName} width={style?.width}>
			<div className={classNames(drawerClassName, "drawer")} style={style} ref={sidePanelRef}>
				{children}
			</div>
		</SidePanel>
	);
};
Drawer.propTypes = {
	style: PropTypes.object,
	sidePanelClassName: PropTypes.string,
	drawerClassName: PropTypes.string,
	parentRef: PropTypes.object,
	onBackdropClick: PropTypes.func,
	isOpen: PropTypes.bool,
	clickAwayIgnoreComponents: PropTypes.arrayOf(PropTypes.string),
};

export default Drawer;
