import { memo } from "react";
import PropTypes from "prop-types";
import { areEqual } from "react-window";
import CalendarMonth from "app/pages/.shared/CalendarList/CalendarMonth";
import "./CalendarListItem.scss";

const CalendarListItem = ({ index, style, data }) => {
	const month = data.months[index];
	return (
		<div className="datepicker-calendar-list-item" style={style}>
			<CalendarMonth
				selectedDate={data.selectedDate}
				handleDateClick={data.handleDateClick}
				departureDateMin={data.departureDateMin}
				departureDateMax={data.departureDateMax}
				month={month}
				checkDateAvailability={data.checkDateAvailability}
			/>
		</div>
	);
};

CalendarListItem.propTypes = {
	index: PropTypes.number,
	style: PropTypes.object,
	data: PropTypes.object,
};

CalendarListItem.defaultProps = {
	style: {},
	data: {},
};

export default memo(CalendarListItem, areEqual);
